import React from "react";
import { withRouter } from "react-router-dom";

import HeaderNavigation from "./HeaderNavigation";
import Footer from "./Footer";

class Layout extends React.Component {
  render() {
    return (
      <>
        <header>
          <HeaderNavigation />
        </header>
        <div className="main-container">{this.props.children}</div>
        <Footer />
      </>
    );
  }
}

export default withRouter(Layout);
