import React from "react";
import { NavLink } from "react-router-dom";

import { ROUTES, images } from "../../constants";

const Footer = () => {
  return (
    <footer>
      <nav className="footer-navigation" role="navigation">
        <NavLink to={ROUTES.root} className="logo" title="Home">
          <img
            src={`${images.logo}`}
            className="responsive-image"
            alt="Cheqmate"
          />
        </NavLink>
        <div>
          <NavLink to={ROUTES.about}>About</NavLink>
          <NavLink to={ROUTES.contact}>Contact</NavLink>
          <NavLink to={ROUTES.faq}>FAQ</NavLink>
        </div>
        <div>
          <NavLink to={ROUTES.terms}>Terms & conditions</NavLink>
          <NavLink to={ROUTES.privacy}>Privacy Policy</NavLink>
        </div>
      </nav>
      <div className="copy-right">© Copyright Cheqmate 2020</div>
    </footer>
  );
};

export default Footer;
