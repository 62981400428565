const ROUTES = {
  root: "/",
  about: "/about",
  contact: "/contact",
  successContact: "/contact/success",
  faq: "/faq",
  terms: "/terms",
  privacy: "/privacy",
  requestDemo: "/request",
  successRequest: "/request/success",
};

const URLS = {
  getImage: "https://cheqmate-images.s3.amazonaws.com/qa-landing-images/",
};

// urls of images
const image = (image, ext = "svg") => `${URLS.getImage}${image}.${ext}`;

const images = {
  logo: image("logo"),
};

const PARTNERS = [
  {
    name: "aloha",
    extension: "svg",
    link: "",
  },
  {
    name: "talech",
    extension: "svg",
    link: "",
  },
  {
    name: "toast",
    extension: "svg",
    link: "",
  },
  {
    name: "google-pay",
    extension: "svg",
    link: "",
  },
  {
    name: "apple-pay",
    extension: "svg",
    link: "",
  },
  {
    name: "feeding-america",
    extension: "svg",
    link: "",
  },
];

const RESTAURANTS = [
  {
    name: "3doughs",
    extension: "svg",
    link: "",
  },
  {
    name: "sports",
    extension: "png",
    link: "",
  },
  {
    name: "tank",
    extension: "png",
    link: "",
  },
  {
    name: "flanigans",
    extension: "png",
    link: "",
  },
  {
    name: "ariete",
    extension: "svg",
    link: "",
  },
];

const TEL_NUMBER_REGEXP = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
const EMAIL_REGEXP = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;

export {
  ROUTES,
  URLS,
  images,
  image,
  PARTNERS,
  RESTAURANTS,
  TEL_NUMBER_REGEXP,
  EMAIL_REGEXP,
};
