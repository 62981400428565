import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";

import { ROUTES } from "../constants";
import Layout from "../components/layout";
import Loader from "../components/common/Loader";

const Home = lazy(() => import("../components/home"));
const About = lazy(() => import("../components/About"));
const Contact = lazy(() => import("../components/Contact"));
const Faq = lazy(() => import("../components/Faq"));
const Privacy = lazy(() => import("../components/Privacy"));
const Terms = lazy(() => import("../components/Terms"));
const RequestDemo = lazy(() => import("../components/RequestDemo"));
const Success = lazy(() => import("../components/Success"));
const NoMatchPage = lazy(() => import("../components/NoMatchPage"));

const Routes = () => (
  <Layout>
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path={ROUTES.root} component={Home} />
        <Route exact path={ROUTES.about} component={About} />
        <Route exact path={ROUTES.contact} component={Contact} />
        <Route
          exact
          path={ROUTES.successContact}
          component={() => <Success isRedirectedFromContacts />}
        />
        <Route exact path={ROUTES.faq} component={Faq} />
        <Route exact path={ROUTES.terms} component={Terms} />
        <Route exact path={ROUTES.privacy} component={Privacy} />
        <Route exact path={ROUTES.requestDemo} component={RequestDemo} />
        <Route exact path={ROUTES.successRequest} component={Success} />
        <Route component={NoMatchPage} />
      </Switch>
    </Suspense>
  </Layout>
);

export default Routes;
