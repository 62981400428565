import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { ROUTES, images } from "../../constants";

const MobileNavigation = () => {
  const containerRef = useRef(null);
  const [isOpen, setOpenMenu] = useState(false);

  useEffect(() => {
    document.addEventListener("click", closeMenu);

    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, []);

  function toggleOpen() {
    setOpenMenu(!isOpen);
    document.body.classList.toggle("overflow-hidden");
  }

  function closeMenu(event) {
    if (containerRef.current.contains(event.target)) {
      setOpenMenu(false);
      document.body.classList.toggle("overflow-hidden");
    }
  }

  return (
    <>
      <button
        className={`menu-button ${isOpen ? "active" : ""}`}
        onClick={toggleOpen}
        type="button"
        title="Menu"
      >
        <span>toggle menu</span>
      </button>
      <div className={`mobile-menu-wrapper ${isOpen ? "open" : ""}`}>
        <div className="mobile-menu" ref={containerRef}>
          <NavLink to={ROUTES.root} className="logo" title="Home">
            <img
              src={`${images.logo}`}
              className="responsive-image"
              alt="Cheqmate"
            />
          </NavLink>
          <NavLink to={ROUTES.about}>About</NavLink>
          <NavLink to={ROUTES.contact}>Contact</NavLink>
          <NavLink to={ROUTES.faq}>FAQ</NavLink>
          <div>
            <NavLink to={ROUTES.terms}>Terms & conditions</NavLink>
            <NavLink to={ROUTES.privacy}>Privacy Policy</NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

const HeaderNavigation = () => (
  <nav className="header-navigation" role="navigation">
    <NavLink to={ROUTES.root} className="logo" title="Home">
      <img src={`${images.logo}`} className="responsive-image" alt="Cheqmate" />
    </NavLink>
    <div className="hide-on-mobile">
      <NavLink to={ROUTES.about}>About</NavLink>
      <NavLink to={ROUTES.contact}>Contact</NavLink>
      <NavLink to={ROUTES.faq}>FAQ</NavLink>
    </div>
    <MobileNavigation />
  </nav>
);

export default HeaderNavigation;
